<template>
  <main-wrapper detail tool-background-color="#409EFF" tool-color="#FFF" class="page__detail">
    <template slot="tool">
      <el-button type="primary" class="flat ipx-0 body-1 el-button--white" @click="$router.go(-1)" v-ripple>
        <i class="el-icon-back im-1"></i>
      </el-button>
      <span class="sub-title ipx-1">{{mode===ADD ? '新增账号' : '修改账号'}}</span>
    </template>
    <template>
      <div class="detail__main clearfix" v-loading="!mountedFlag">

        <div class="card-block">
          <p class="block__title">
            账号信息
          </p>
          <div class="block__body">
            <el-tabs >
              <el-tab-pane label="基本">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                  <el-form-item label="所属用户">
                    <span class="">{{form.HoldName}}</span>
                    <!-- <el-input v-model="form.HoldName" class="v-input"></el-input> -->
                  </el-form-item>
                  <el-form-item label="姓名" prop="RealName">
                    <el-input v-model="form.RealName" class="v-input"></el-input>
                  </el-form-item>
                  <el-form-item label="账号" prop="UserName">
                    <el-input v-model="form.UserName" class="v-input" :disabled="mode==MODIFY"></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="UserPassword">
                    <el-input v-model="form.UserPassword" :type="(loginInfo.HoldID===1 || loginInfo.UserID===11174) ? 'text' : 'password'" class="v-input"></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="UserPasswordAgian">
                    <el-input v-model="form.UserPasswordAgian" :type="(loginInfo.HoldID===1 || loginInfo.UserID===11174) ? 'text' : 'password'" class="v-input"></el-input>
                  </el-form-item>
                  <el-form-item label="启用日期" prop="StartTime">
                    <el-date-picker v-model="form.StartTime" type="datetime" placeholder="请选择启用日期"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="截止日期" prop="EndTime">
                    <el-date-picker v-model="form.EndTime" type="datetime" placeholder="请选择截止日期"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.Remark"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="okHandle">提交</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="权限" style="height:500px;overflow-y:auto;">
                <el-tree
                  :check-strictly="true"
                  ref="tree"
                  :data="fun"
                  show-checkbox
                  node-key="id"
                  :default-checked-keys="hasFun"
                  :props="defaultProps"
                  @check="checkHandler">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ $t('funMenu')[node.label] }}</span>
                  </span>
                </el-tree>
              </el-tab-pane>
            </el-tabs>

          </div>
        </div>

      </div>
    </template>
  </main-wrapper>
</template>

<script>
import * as API from '@/api/user'
import mixin from './mixin-detail'
import miment from 'miment'

const ADD = 'ADD'
const MODIFY = 'MODIFY'
export default {
  props: {
    itemId: {
      type: String,
      default: null
    }
  },
  mixins: [mixin],
  data() {
    return {
      ADD,
      MODIFY,
      form: {
        UserID: -1,
        UserName: '',
        UserPassword: '',
        UserPasswordAgian: '',
        UserType: -1,
        HoldID: -1,
        HoldName: '',
        ParentUserID: null,
        UpdateTime: new Date(),
        isDeleted: false,
        Remark: '',
        RealName: '',
        StartTime: new Date(),
        EndTime: miment().add(1, 'YYYY'),
        DefaultFunID: null,
        DefaultMapTypeID: null,
        DefaultModel: null,
        DefaultOverTimeDays: 3,
        TUserID: null
      },

      rules: {
        UserName: [ {required: true,message:'请输入用户账号',trigger: 'blur'} ],
        UserPassword: [ {required: true,message:'请输入用户密码',trigger: 'blur'} ],
        RealName: [ {required: true,message:'请输入用户姓名',trigger: 'blur'} ],
        StartTime: [ {required: true,message:'请选择启用日期',trigger: 'change'} ],
        EndTime: [ {required: true,message:'请选择截止日期',trigger: 'change'} ],
        UserPasswordAgian: [{required: true, message: '请再次输入密码'},{validator:this.validatePass, trigger: 'blur'}]
      },

      fun: [],
      hasFun: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label(d) {
          return d.label
        },
        disabled() {
          return false
        }
      }
    }
  },
  computed: {
    mode() {
      return this.itemId === null ? ADD : MODIFY
    },
    isSelf() {
      return this.itemId == this.loginInfo.UserID
    }
  },
  created() {
    if (this.mode == ADD) {
      this.mountedFlag = true
      this.form.HoldID = this.currentHoldId
      this.form.HoldName = this.currentHoldName
      this.fetchFun()
    } else {
      this.fetchData()
    }

    this.defaultProps.disabled = () => {
      return this.isSelf
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    fetchData() {
      API.QueryUserDetail(this.itemId).then(ret => {
        this.form = Object.assign(this.form, ret.data.user, {
          HoldName: ret.data.holdInfo.Name,
          UserPasswordAgian: ret.data.user.UserPassword
        })
        this.fun = ret.data.fun.slice()
        this.hasFun = ret.data.hasFun.slice()
        this.mountedFlag = true
      })
    },
    fetchFun() {
      API.GetFunTree(this.form.HoldID, 1).then(ret => {
        this.fun = ret.data.fun.slice()
        this.hasFun = ret.data.hasFun.slice()
      })
    },
    async okHandle() {
      try {
        await this.valid()
      } catch (e) {
        return
      }
      const newFun = this.$refs.tree.getCheckedKeys()
      if (this.mode == ADD) {
        API.Add(this.form, newFun).then(ret => {
            if (!ret.data.errCode) {
              this.$message({
                message: this.$t('common.success'),
                type: 'success'
              })
              this.$emit('refresh')
              this.$router.go(-1)
            } else {
              // 错误
              this.print('error', ret.data.errMsg)
            }
        }).catch(err => {
          // 错误
          this.print('error', err.message)
        })
      } else {
        API.Modify(this.form, newFun).then(ret => {
            if (!ret.data.errCode) {
              this.$emit('refresh')
              this.$router.go(-1)
            } else {
              // 错误
              this.print('error', ret.data.errMsg)
            }
        }).catch(err => {
          // 错误
          this.print('error', err.message)
        })
      }
    },
    valid() {
      const promise = new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
      return promise
    },
    validatePass(rule, value, callback) {
      console.log(value, this.form.UserPassword, value != this.form.UserPassword)
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value != this.form.UserPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    },
    checkHandler(node, {checkedKeys, checkedNodes}) {
      console.log(node)
      // 判断节点是否被选中
      const isChecked = checkedNodes.includes(node)
      console.log('是否选中', isChecked)

      // 获取所有子节点
      const allChildrenKeys = []
      const getChildrenKeys = (node) => {
        if (!node.children) {
          return allChildrenKeys
        } else {
          node.children.forEach(j => {
            allChildrenKeys.push(j.id)// 添加子节点
            getChildrenKeys(j)
          })
        }
      }
      getChildrenKeys(node)
      console.log('所有子节点', allChildrenKeys)

      // checkedKeys中移除或添加后，设置选中节点
      if (isChecked) {
        allChildrenKeys.forEach(k => {
          if (!checkedKeys.includes(k)) {
            checkedKeys.push(k)
          }
        })
      } else {
        allChildrenKeys.forEach(k => {
          if (checkedKeys.includes(k)) {
            const index = checkedKeys.indexOf(k)
            checkedKeys.splice(index, 1)
          }
        })
      }

      this.$refs.tree.setCheckedKeys(checkedKeys)
    }
  }
}
</script>

<style lang="scss" scoped>
.title,.sub-title  {
  color: inherit;
}
.v-input {
  max-width: 215px;
}
.detail__main {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .card-block {
    margin: 12px auto 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

    .block__title {
      line-height: 38px;
      height: 38px;
      padding: 0 16px;
      border-bottom: 1px solid #DCDFE6;
      color: #303133;
    }
    .block__body {
      padding: 12px 24px;
      & ::v-deep .el-tree-node__label {
        font-size: 13px;
      }
    }
  }
}

@media screen and (min-width: 1272px) {
  .detail__main {
    //max-width: 1296px;
    .card-block {
      width: 600px;
      margin: 12px 0 0 24px;
    }
  }
}

.el-tab-pane {
  height: 500px;
  overflow-y: auto;
}
</style>
